/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import Link from "./link"
import StoryCardMini from "./story-card-mini"
import CTA from "./cta"
import ctaArrow from "../images/cta-arrow.svg"

const FeaturedStories = ({ stories }) => {
  return (
    <React.Fragment>
      <div
        sx={{
          display: "grid",
          gridTemplateColumns: ["1fr", "1fr", "1fr 1fr", "0.64fr 0.36fr"],
          columnGap: "3px",
          rowGap: "3px",
        }}
      >
        {stories[0] && (
          <React.Fragment>
            <StoryCardMini
              type="large"
              sx={{
                backgroundColor: "swatches.primary.teal",
                display: ["none", "none", "flex"],
              }}
              story={stories[0]}
            />
            <StoryCardMini
              type="secondary"
              sx={{
                backgroundColor: "swatches.primary.teal",
                display: ["flex", "flex", "none"],
              }}
              story={stories[0]}
            />
          </React.Fragment>
        )}
        <div>
          {stories[1] && (
            <React.Fragment>
              <StoryCardMini
                type="secondary"
                sx={{
                  backgroundColor: "swatches.primary.tan",
                  mb: "3px",
                  display: "flex",
                }}
                story={stories[1]}
              />
            </React.Fragment>
          )}
          {stories[2] && (
            <React.Fragment>
              <StoryCardMini
                type="secondary"
                sx={{
                  backgroundColor: "swatches.primary.green",
                  display: "flex",
                }}
                story={stories[2]}
              />
            </React.Fragment>
          )}
        </div>
      </div>
      <div
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          mt: "32px",
        }}
      >
        <CTA
          link="/stories"
          sx={{
            display: "flex",
            flexDirection: "row",
            px: "50px",
            justifyContent: "center",
            alignItems: "center",
            ":hover": {
              img: {
                filter: "brightness(1000%)",
              },
            },
          }}
        >
          <div sx={{ mt: "2px" }}>See All Stories</div>
          <img sx={{ height: "15px", ml: "16px" }} src={ctaArrow} />
        </CTA>
      </div>
    </React.Fragment>
  )
}

export default FeaturedStories
