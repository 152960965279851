/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import { toSlug } from "../helpers"
import Link from "./link"
import { useWindowSize } from "react-use"
import { useIsClient } from "../hooks/use-is-client"

const StoryCardMini = ({ story, type, ...restProps }) => {
  const titleRef = React.useRef(null)
  const screenSize = useWindowSize()
  const isClient = useIsClient()
  const [currentWidth, setCurrentWidth] = React.useState(null)
  React.useEffect(() => {
    isClient &&
      titleRef?.current &&
      setCurrentWidth(titleRef.current.clientWidth)
  }, [isClient, titleRef, screenSize.width])
  const maxLength = story.title
    .split(" ")
    .reduce((max, curr) => (curr.length > max.length ? curr : max)).length
  const dynamicSize = (currentWidth / maxLength) * 1.4
  return (
    <Link
      link={`/story/${toSlug(story.title)}-${story.contentful_id}`}
      sx={{
        display: "flex",
        backgroundColor: "swatches.secondary.tan",
        color: type === "large" ? "#2F3334" : "#2A2D2E",
        ":hover": {
          color: type === "large" ? "#2F3334" : "#2A2D2E",
        },
      }}
      {...restProps}
    >
      <div
        title={story.title}
        sx={{
          display: "flex",
          flexDirection: "row",
          height:
            type === "secondary"
              ? ["200px", "200px", "200px", "281px"]
              : type === "large"
              ? ["400px", "400px", "400px", "565px"]
              : ["200px", "200px", "200px", "277px"],
          width: "100%",
        }}
      >
        {story.images && (
          <img
            sx={{
              width:
                type === "secondary"
                  ? ["130px", "130px", "130px", "150px", "184px"]
                  : type === "large"
                  ? ["130px", "160px", "160px", "300px"]
                  : ["130px", "130px", "130px", "180px"],
              height: "100%",
              objectFit: "cover",
            }}
            src={story.images[0].file.url}
          />
        )}
        <div
          sx={{
            flexGrow: 1,
            ...(type === "large" ? { px: "31px", py: "26px" } : { p: "18px" }),
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div
            sx={{
              fontSize: ["10px", "12px"],
              letterSpacing: "1.5px",
              fontWeight: "500",
              mb: "9px",
            }}
          >
            /STORIES
          </div>
          <h4
            ref={titleRef}
            sx={{
              flexGrow: 1,
              mb: "18px",
              fontSize: (type === "secondary"
                ? [24, 24, 24, 28]
                : type === "large"
                ? [30, 30, 26, 38]
                : [21, 21, 18, 26]
              ).map(size => (size < dynamicSize ? size : dynamicSize)),
              wordBreak: "break-word",
            }}
          >
            {type !== "large" && story.title.length > 41
              ? `${story.title.substring(0, 40).trim()}...`
              : story.title}
          </h4>
          {story.author && (
            <div sx={{ fontSize: "13px" }}>By {story.author.name}</div>
          )}
        </div>
      </div>
    </Link>
  )
}

export default StoryCardMini
