/** @jsx jsx */
import { jsx } from "theme-ui"
import { formatCardDate, toSlug } from "../helpers"
import Link from "./link"

const EventCardMini = ({ event, type, ...restProps }) => {
  return (
    <div
      sx={{
        width: "100%",
        height: "100%",
      }}
      {...restProps}
    >
      <Link
        title={event.eventName}
        link={`/event/${toSlug(event.eventName)}-${event.contentful_id}`}
        sx={{
          color: "initial",
          "&:hover": { color: ["unset", "unset", "primary"] },
        }}
      >
        <div
          sx={{
            display: "flex",
            flexDirection: "row",
            height: "100%",
            width: "100%",
            ...(type === "highlights" && {
              borderTop: "1px solid #A5A5A5",
              pt: "10px",
            }),
          }}
        >
          <div
            sx={{
              backgroundImage:
                event?.eventImage?.file?.url &&
                `url(${event.eventImage.file.url}?w=512&h=512&fit=fill)`,
              minHeight: ["150px", "170px", "193px"],
              minWidth: ["150px", "170px", "193px"],
              backgroundPosition: "center",
              backgroundSize: "cover",
            }}
          ></div>
          <div
            sx={{
              height: ["150px", "170px", "193px"],
              flexGrow: 1,
              py: "5px",
              ml: "19px",
              ...(type !== "highlights" && { borderTop: "1px solid #A5A5A5" }),
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              flexShrink: 1,
            }}
          >
            <div>
              <div
                sx={{
                  fontSize: "12px",
                  color: "#3E4447",
                }}
              >
                /{event.discipline.disciplineName.toUpperCase()}
              </div>
              <h4
                sx={{
                  mt: "10px",
                  mb: "5px",
                  fontSize: ["14px", "14px", "14px", "16px"],
                  lineHeight: "1.5",
                  wordBreak: ["break-all", "unset"],
                }}
              >
                <span sx={{ display: ["block", "block", "none"] }}>
                  {event.eventName.substring(0, 25)}
                  {event.eventName.length > 25 && "..."}
                </span>
                <span sx={{ display: ["none", "none", "block"] }}>
                  {event.eventName.substring(0, 50)}
                  {event.eventName.length > 50 && "..."}
                </span>
              </h4>
            </div>
            <div
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-end",
                lineHeight: "18px",
                fontWeight: "400",
                color: "#3E4447",
              }}
            >
              <p sx={{ fontSize: "13px", mb: 0 }}>
                {formatCardDate(event.eventStartDate, event.eventEndDate)}
              </p>
              <p
                sx={{
                  fontSize: "13px",
                  mb: 0,
                  marginBottom: 0,
                  fontWeight: "400",
                  color: "#3E4447",
                }}
              >
                <span sx={{ display: ["block", "block", "none"] }}>
                  {event.organization.name.substring(0, 20)}
                  {event.organization.name.length > 20 && "..."}
                </span>
                <span sx={{ display: ["none", "none", "block"] }}>
                  {event.organization.name.substring(0, 40)}
                  {event.organization.name.length > 40 && "..."}
                </span>
              </p>
            </div>
          </div>
        </div>
      </Link>
    </div>
  )
}

export default EventCardMini
