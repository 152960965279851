/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import SwipeableViews from "react-swipeable-views"
import { breakpointList } from "../helpers"
import chevronR from "../images/carousel-chevron-R.svg"
import { isArray } from "lodash"

const SwipeableView = ({
  children: rawChildren,
  visibleElements = [1, 1, 2, 2, 3, 3, 3],
  bleeding = 20,
  onNext,
  onPrev,
  ...restProps
}) => {
  const children = isArray(rawChildren) ? rawChildren : [rawChildren]
  const wrapperRef = React.useRef(null)
  const innerRef = React.useRef(null)
  const [wrapperWidth, setWrapperWidth] = React.useState(0)
  const [innerWidth, setInnerWidth] = React.useState(0)
  const [activeIndex, setActiveIndex] = React.useState(0)
  const [currentVisibleElements, setCurrentVisibleElements] = React.useState(1)
  const disabled = wrapperWidth > innerWidth * children.length
  React.useEffect(() => {
    function handleResize() {
      setWrapperWidth(wrapperRef.current.clientWidth)
      setInnerWidth(innerRef.current.clientWidth)
      const breakpointIndex = breakpointList
        .map(breakpoint => parseInt(breakpoint.split("px")[0]))
        .filter(breakpoint => breakpoint < window.innerWidth).length
      const newCurrentVisibleElements = visibleElements[breakpointIndex]
      if (newCurrentVisibleElements !== currentVisibleElements)
        setCurrentVisibleElements(newCurrentVisibleElements)
    }
    handleResize()
    window.addEventListener("resize", handleResize)
    return _ => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])
  React.useEffect(() => {
    if (disabled) {
      setActiveIndex(0)
    }
  }, [disabled])
  const rest = children.length % currentVisibleElements
  const last = children.length - (rest > 0 ? rest : currentVisibleElements)
  const reset = activeIndex > last
  React.useEffect(() => {
    if (reset && currentVisibleElements > 1) setActiveIndex(last)
  }, [reset, currentVisibleElements, last, setActiveIndex])
  const buttonContainerStyle = {
    mx: 0,
    "& *": {
      display: [
        "none",
        "none",
        "none",
        children.length <= currentVisibleElements ? "none" : "block",
      ],
    },
    display: "flex",
    width: 0,
    overflow: ["hidden", "hidden", "visible"],
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  }
  return (
    <div sx={{ display: "flex", flexDirection: "row" }}>
      <div sx={buttonContainerStyle}>
        <button
          onClick={() =>
            setActiveIndex(activeIndex =>
              onPrev
                ? onPrev(activeIndex)
                : activeIndex === 0
                ? 0
                : activeIndex - 1
            )
          }
          sx={{
            width: "33px",
            userSelect: "none",
            backgroundColor: "transparent",
            mr: "50px",
            ...(activeIndex === 0 && { opacity: 0.2 }),
          }}
        >
          <img
            src={chevronR}
            sx={{ width: "18px", transform: "rotate(180deg)" }}
            alt="Prev"
          />
        </button>
      </div>
      <div
        sx={{ overflow: "hidden", flexGrow: 1 }}
        ref={ref => {
          wrapperRef.current = ref
          if (ref) setWrapperWidth(ref.clientWidth)
        }}
      >
        <SwipeableViews
          index={activeIndex}
          onChangeIndex={setActiveIndex}
          enableMouseEvents={true}
          disabled={disabled}
          resistance={true}
          sx={{
            userSelect: disabled ? "all" : "none",
            width: innerWidth,
            overflowX: "visible!important",
            ml: "1px",
          }}
        >
          {children.map((child, index) => (
            <div
              key={index}
              ref={ref => {
                if (index === 0) {
                  innerRef.current = ref
                  if (ref) setInnerWidth(ref.clientWidth)
                }
              }}
              sx={{
                display: "flex",
                flexDirection: "column",
                height: "100%",
                width: Array.isArray(visibleElements)
                  ? visibleElements.map(
                      (visibleElements, index) =>
                        wrapperWidth / visibleElements -
                        (index < 4 ? bleeding[index] : 0)
                    )
                  : Array.isArray(bleeding)
                  ? bleeding.map(
                      bleeding => wrapperWidth / visibleElements - bleeding
                    )
                  : wrapperWidth / visibleElements - bleeding,
                pr: "0px",
              }}
            >
              {child}
            </div>
          ))}
        </SwipeableViews>
      </div>
      <div sx={buttonContainerStyle}>
        <button
          sx={{
            width: "33px",
            userSelect: "none",
            backgroundColor: "transparent",
            ml: "85px",
            ...(activeIndex === last && { opacity: 0.2 }),
          }}
          disabled={activeIndex === last ? true : false}
          onClick={() =>
            setActiveIndex(activeIndex =>
              onNext ? onNext(activeIndex) : activeIndex + 1
            )
          }
        >
          <img src={chevronR} sx={{ width: "18px" }} alt="Next" />
        </button>
      </div>
    </div>
  )
}
export default SwipeableView
